import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AlertService } from 'src/app/_services/alert.service';
import { LaravelProjectService } from 'src/app/_services/laravel/laravel-project.service';
import { AppState } from 'src/app/_store/app.reducer';
import * as RouterActions from 'src/app/_store/router.actions';
import { ProjectSelectionComponent } from 'src/app/commons/project-selection/project-selection.component';
import { iif, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as ProjectActions from '../actions/project.actions';
import * as ProjectSelectors from '../selectors/project.selectors';

@Injectable()
export class ProjectEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.saveProjectFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProjects),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.projectService.getProjects(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              ProjectActions.loadProjectsCompleted({ projects: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(ProjectActions.loadProjectsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.changePage),
      withLatestFrom(this.store$.select(ProjectSelectors.getProjectsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => ProjectActions.loadProjects({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.changeSort),
      withLatestFrom(this.store$.select(ProjectSelectors.getProjectsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => ProjectActions.loadProjects({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.changeFilters),
      withLatestFrom(this.store$.select(ProjectSelectors.getProjectsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => ProjectActions.loadProjects({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  showProject = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.showProject),
      map(({ currentProject }) => RouterActions.routerGo({ path: ['/project/', currentProject.id] }))
    )
  );

  addProject = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.addProject),
      map(() => RouterActions.routerGo({ path: ['/project/new'] })),
    )
  );

  loadCurrentProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadCurrentProject),
      withLatestFrom(this.store$.select(ProjectSelectors.getCurrentProject)),
      switchMap(([_, currentProject]) => {
        return this.projectService.getProjectById(currentProject.id, ["client", "accountant"])
          .pipe(
            map(result =>
              ProjectActions.loadCurrentProjectCompleted({ currentProject: result })
            ),
            catchError(error => {
              return of(ProjectActions.loadCurrentProjectFailed({ error }))
            })
          )
      })
    )
  );


  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProject),
      switchMap(({ id }) => {
        return this.projectService.getProjectById(id, ["client", "accountant"])
          .pipe(
            map(result =>
              ProjectActions.loadProjectCompleted({ project: result })
            ),
            catchError(error => {
              return of(ProjectActions.loadProjectFailed({ error }))
            })
          )
      })
    )
  );

  saveProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.saveProject),
      switchMap(({ project }) =>
        this.projectService.upsertProject(project)
          .pipe(
            map(result =>
              ProjectActions.saveProjectCompleted({ project: result })
            ),
            catchError(error => of(ProjectActions.saveProjectFailed({ error })))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.saveProjectCompleted),
      map(action => action.project),
      tap(project => this.alertService.showConfirmMessage(`Commessa ${project.identifier} salvata con successo`)),
      map(() => ProjectActions.closeProjectDialog())
    )
  );

  deleteProjectFilled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProject),
      filter(({ project }) => !!project.billing_lines?.length || !!project.activities?.length,),
      tap(() => this.alertService.showAlertDialog('Impossibile eliminare', 'Non si possono eliminare le commesse con rate di fatturazione o attività presenti'))
    ), { dispatch: false }
  );

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProject),
      filter(({ project }) => !project.billing_lines?.length && !project.activities?.length,),
      switchMap(({ project }) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare la commessa ${project.identifier}?`)
          .pipe(
            filter(confirm => confirm),
            switchMap(() =>
              this.projectService.deleteProject(project.id)
                .pipe(
                  map(() => ProjectActions.deleteProjectCompleted({ project })),
                  catchError(error => of(ProjectActions.deleteProjectFailed({ error })))
                )
            )
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProjectCompleted),
      tap(({ project }) => this.alertService.showConfirmMessage(`Commessa ${project.identifier} eliminata con successo`)),
      map(() => RouterActions.routerGo({ path: ['/projects'] })),
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.closeProjectDialog),
      map(() => RouterActions.routerGo({ path: ['/projects'] })),
    )
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.saveProjectCompleted),
      withLatestFrom(this.store$.select(ProjectSelectors.getProjectsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ProjectActions.loadProjects({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProjectCompleted),
      withLatestFrom(this.store$.select(ProjectSelectors.getProjectsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ProjectActions.loadProjects({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  selectProject$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectActions.selectProject),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(ProjectSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return ProjectActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );

  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(ProjectSelectors.getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  projectsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.projectSelected),
      map(() => ProjectActions.closeSelectionDialog())
    ))

  nextProgressive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.nextProgressive),
      switchMap(({ area, id }) => {
        return this.projectService.getNextProgressive(area, id)
          .pipe(
            map(result =>
              ProjectActions.nextProgressiveCompleted({ progressive: result, id, area })
            ),
            catchError(error => {
              return of(ProjectActions.nextProgressiveFailed({ error }))
            })
          )
      })
    )
  );

  duplicateMulticlientProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.duplicateProject),
      filter(({ project }) => project.multiclient),
      tap(() => this.alertService.showAlertDialog('Impossibile duplicare', 'Impossibile duplicare una commessa multicliente'))
    ), { dispatch: false }
  );

  duplicateProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.duplicateProject),
      filter(({ project }) => !project.multiclient),
      map(({ project }) => RouterActions.routerGo({ path: ['/project/new', project.id] })),
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private projectService: LaravelProjectService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}
