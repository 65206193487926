import { AbstractControl } from '@angular/forms';

import { formatDateForBackend } from '../_helpers/utils';
import { Attachment, AttachmentDTO } from './attachment';
import { Address, AddressDTO, Base, BaseDTO } from './base';
import { Org, OrgDTO } from './org';
import { Participant, ParticipantDTO } from './planning';
import { Registration } from './registration';
import { Service, ServiceDTO } from './service';

export interface EmployeeDTO extends BaseDTO {
  name: string;
  surname: string;
  org_id?: number;
  org?: OrgDTO;
  address?: AddressDTO;
  phone?: string;
  email?: string;
  birthdate?: Date;
  birthplace?: string;
  birthprovince?: string;
  identifier?: string;
  smac?: string;
  job?: string;
  foreign?: boolean;
  italian_speak?: boolean;
  note?: string;
  deadlines?: DeadlineDTO[];
  attachments?: AttachmentDTO[];
  participations?: ParticipantDTO[];
  external_id?: string;
}

export class Employee extends Base {
  name: string;
  surname: string;
  org: Org;
  orgId?: number;
  phone?: string;
  email?: string;
  birthdate?: Date;
  birthplace?: string;
  birthprovince?: string;
  identifier?: string;
  smac?: string;
  address?: Address;
  job?: string;
  foreign?: boolean;
  italianSpeak?: boolean;
  note?: string;
  deadlines?: Deadline[];
  attachments?: Attachment[] = [];
  participations?: Participant[];
  externalId?: string;

  constructor(dto: EmployeeDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      this.name = dto.name;
      this.surname = dto.surname;
      this.phone = dto.phone;
      this.email = dto.email;
      this.orgId = dto.org_id;
      if (dto.org) {
        this.org = new Org(dto.org);
        this.orgId = this.org.objectId;
        this.addLoadedRelation("org");
      }
      if (dto.birthdate) {
        this.birthdate = new Date(dto.birthdate);
      }
      if (dto.address) {
        this.address = new Address(dto.address);
      }
      if (dto.attachments) {
        this.attachments = dto.attachments.map(
          attachment => new Attachment(attachment)
        );
        this.addLoadedRelation("attachments");
      }
      if (dto.deadlines) {
        this.deadlines = dto.deadlines.map(deadline => new Deadline(deadline));
        this.addLoadedRelation("deadlines");
      }
      if (dto.participations) {
        this.participations = dto.participations.map(participation => new Participant(participation));
        this.addLoadedRelation("participations");
      }
      this.birthplace = dto.birthplace;
      this.birthprovince = dto.birthprovince;
      this.identifier = dto.identifier;
      this.smac = dto.smac;
      this.job = dto.job;
      this.foreign = dto.foreign;
      this.italianSpeak = dto.italian_speak;
      this.note = dto.note;
      this.externalId = dto.external_id;
    }
  }

  public toDTO(): EmployeeDTO {
    let result: EmployeeDTO = <EmployeeDTO>super.toDTO();
    Object.assign(result, {
      name: this.name,
      surname: this.surname,
      org_id: this.org ? this.org.objectId : null,
      phone: this.phone,
      email: this.email,
      birthdate: formatDateForBackend(this.birthdate),
      birthplace: this.birthplace,
      birthprovince: this.birthprovince,
      job: this.job,
      note: this.note,
      identifier: this.identifier,
      smac: this.smac,
      address: this.address,
      foreign: this.foreign,
      italian_speak: this.italianSpeak,
      attachments: (this.isRelationLoaded("attachments") && this.attachments)
        ? this.attachments.map(attachment => attachment.toDTO())
        : [],
      external_id: this.externalId
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: Employee
  ): Employee {
    const formModel = formGroup.value;

    let employee: Employee = new Employee(null);

    employee.name = formModel.name;
    employee.surname = formModel.surname;
    employee.phone = formModel.phone;
    employee.email = formModel.email;
    employee.birthdate = formModel.birthdate
      ? new Date(formModel.birthdate)
      : null;
    employee.birthprovince = formModel.birthprovince;
    employee.birthplace = formModel.birthplace;
    employee.identifier = formModel.identifier
      ? formModel.identifier.toUpperCase()
      : null;
    employee.job = formModel.job;
    employee.smac = formModel.smac;
    employee.address = formModel.address;
    employee.foreign = formModel.foreign;
    employee.italianSpeak = formModel.italianSpeak;
    employee.note = formModel.note;
    employee.externalId = formModel.externalId;

    if (original) {
      employee.objectId = original.objectId;
      employee.loadedRelations = original.loadedRelations;
    }
    return employee;
  }

  static fromRegistration(registration: Registration): Employee {
    let employee: Employee = new Employee(null);

    employee.name = registration.name;
    employee.surname = registration.surname;
    employee.phone = registration.phone;
    employee.email = registration.email;
    employee.birthdate = registration.birthdate
      ? new Date(registration.birthdate)
      : null;
    employee.birthprovince = registration.birthprovince;
    employee.birthplace = registration.birthplace;
    employee.note = registration.note;
    employee.identifier = registration.identifier;

    if (registration.type == 'private' && registration.orgData) {
      employee.address = registration.orgData.address;
    }

    return employee;
  }

  get fullName(): string {
    return `${this.name} ${this.surname}`;
  }

  get archived(): boolean {
    return this.deletedAt != null;
  }
}

export interface EmployeeFilter {
  queryString?: string;
  includeArchived?: boolean;
}

export interface DeadlineDTO extends BaseDTO {
  employee_id?: number;
  employee?: EmployeeDTO;
  service_id?: number;
  service?: ServiceDTO;
  partecipation_id?: number;
  partecipation?: ParticipantDTO;
  execution_date: Date;
  expiration_date: Date;
  remind_date: Date;
  note?: string;
}

export class Deadline extends Base {
  employee: Employee;
  service: Service;
  partecipation?: Participant;
  executionDate: Date;
  expirationDate: Date;
  remindDate: Date;
  note?: string;

  constructor(dto: DeadlineDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      if (dto.employee) {
        this.employee = new Employee(dto.employee);
        this.addLoadedRelation("employee");
      }
      if (dto.execution_date) {
        this.executionDate = new Date(dto.execution_date);
      }
      if (dto.expiration_date) {
        this.expirationDate = new Date(dto.expiration_date);
      }
      if (dto.remind_date) {
        this.remindDate = new Date(dto.remind_date);
      }
      this.note = dto.note;
      if (dto.service) {
        this.service = new Service(dto.service);
        this.addLoadedRelation("service");
      }
      if (dto.partecipation) {
        this.partecipation = new Participant(dto.partecipation);
        this.addLoadedRelation("partecipation");
      }
    }
  }

  public toDTO(): DeadlineDTO {
    let result: DeadlineDTO = <DeadlineDTO>super.toDTO();
    Object.assign(result, {
      employee_id: this.employee ? this.employee.objectId : null,
      service_id: this.service ? this.service.objectId : null,
      partecipation_id: this.partecipation ? this.partecipation.objectId : null,
      execution_date: formatDateForBackend(this.executionDate),
      expiration_date: formatDateForBackend(this.expirationDate),
      remind_date: formatDateForBackend(this.remindDate),
      note: this.note
    });
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: Deadline
  ): Deadline {
    const formModel = formGroup.value;

    let deadline: Deadline = new Deadline(null);
    deadline.service = formModel.service;
    deadline.executionDate = formModel.executionDate
      ? new Date(formModel.executionDate)
      : null;
    deadline.expirationDate = formModel.expirationDate
      ? new Date(formModel.expirationDate)
      : null;
    deadline.remindDate = formModel.remindDate
      ? new Date(formModel.remindDate)
      : null;
    deadline.note = formModel.note;
    deadline.employee = formModel.employee;

    if (original) {
      deadline.objectId = original.objectId;
      deadline.loadedRelations = original.loadedRelations;
    }
    return deadline;
  }
}

export class DeadlineFilter {
  includeArchived?: boolean = false;
  employeeId?: number;
  orderId?: number;
  serviceId?: number;
  orgId?: number;
  reminderStart?: Date;
  reminderEnd?: Date;
  expirationStart?: Date;
  expirationEnd?: Date;
  onlyPrivate: boolean;
  employeeQueryString?: string;
  orderQueryString?: string;
}
