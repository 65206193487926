import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/_helpers/listResult.interface';
import { formatDateForBackend } from 'src/app/_helpers/utils';
import { BillingLineDTO } from 'src/app/_models/billing-line.model';
import { InvoiceDTO, InvoiceFilters } from 'src/app/_models/invoice';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: "root"
})
export class LaravelInvoiceService {

  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment["laravel"]["serverUrl"] + "/api/invoices",
      show: environment["laravel"]["serverUrl"] + "/api/invoice",
      store: environment["laravel"]["serverUrl"] + "/api/invoice",
      cancel: environment["laravel"]["serverUrl"] + "/api/invoice/cancel",
      detach: environment["laravel"]["serverUrl"] + "/api/invoices/detach",
      new: environment["laravel"]["serverUrl"] + "/api/invoice/new",
      checkInvoiceCode: environment["laravel"]["serverUrl"] + "/api/checkInvoiceCode",
      XMLinvoice: environment["laravel"]["serverUrl"] + "/api/invoiceXML",
      XMLMultipleInvoice: environment["laravel"]["serverUrl"] + "/api/invoicesXML",
      PDFinvoice: environment["laravel"]["serverUrl"] + "/api/invoicePDF",
    };
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters: InvoiceFilters,
    includes?: string[]
  ): Observable<ListResultDTO<InvoiceDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.identifier) params["identifier"] = "" + filters.identifier;
      if (filters.org) params["org_id"] = "" + filters.org.objectId;
      if (filters.from) params["from"] = formatDateForBackend(filters.from);
      if (filters.to) params["end"] = formatDateForBackend(filters.to);
      if (filters.canceled) params["canceled"] = "" + filters.canceled;
      if (filters.downloaded != undefined && filters.downloaded != null) params["downloaded"] = filters.downloaded;
      if (filters.downloaded) {
        if (filters.download_date_from) params["download_date_from"] = formatDateForBackend(filters.download_date_from);
        if (filters.download_date_to) params["download_date_to"] = formatDateForBackend(filters.download_date_to);
      }
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<InvoiceDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<InvoiceDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  public new(mode: string, billing_lines: BillingLineDTO[]): Observable<InvoiceDTO[]> {
    return this.httpClient.post<InvoiceDTO[]>(this.ROUTES.new, {
      mode,
      billing_lines
    })
  }


  public getInvoiceById(id: number): Observable<InvoiceDTO> {
    let params = { id: "" + id };
    return this.httpClient.get<InvoiceDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });

  }

  public upsert(invoice: InvoiceDTO): Observable<InvoiceDTO> {
    if (invoice.id) {
      return this.httpClient.put<InvoiceDTO>(`${this.ROUTES.store}`, { invoice });
    } else {
      return this.httpClient.post<InvoiceDTO>(`${this.ROUTES.store}`, { invoice });
    }
  }


  public cancel(invoice: Partial<InvoiceDTO>): Observable<InvoiceDTO> {
    return this.httpClient.put<InvoiceDTO>(this.ROUTES.cancel, {
      invoice
    })
  }
  public detach(billing_line: Partial<BillingLineDTO>): Observable<BillingLineDTO> {
    return this.httpClient.put<BillingLineDTO>(this.ROUTES.detach, {
      billing_line
    })
  }

  public checkInvoiceCode(invoiceId: number, code: number): Observable<boolean> {
    let params = {};
    if (code) params["code"] = "" + code;
    if (invoiceId) params["invoice_id"] = "" + invoiceId;
    return this.httpClient.get<boolean>(this.ROUTES.checkInvoiceCode, {
      params: new HttpParams({ fromObject: params })
    });
  }


  public getInvoiceXML(id: number): Observable<Blob> {
    let params = { id: "" + id };

    return this.httpClient.get(this.ROUTES.XMLinvoice, {
      params: new HttpParams({
        fromObject: params,
      }),
      responseType: "blob",
    });
  }

  public getInvoicesXML(ids: number[]): Observable<Blob> {
    let params = { "ids[]": ids.map(id => "" + id) };

    return this.httpClient.get(this.ROUTES.XMLMultipleInvoice, {
      params: new HttpParams({
        fromObject: params,
      }),
      responseType: "blob",
    });
  }

  public getInvoicePDF(id: number): Observable<Blob> {
    let params = { id: "" + id };

    return this.httpClient.get(this.ROUTES.PDFinvoice, {
      params: new HttpParams({
        fromObject: params,
      }),
      responseType: "blob",
    });
  }
}

