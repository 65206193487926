import { AbstractControl } from '@angular/forms';
import { BillingLineDTO } from 'src/app/_models/billing-line.model';
import { BillingLine } from './billing-line.model';
import { Employee, EmployeeDTO } from './employee';

import { formatDateForBackend } from '../_helpers/utils';
import { Base, BaseDTO } from './base';
import { Org, OrgDTO, PaymentMethod, PaymentTime } from './org';


export const INVOICE_MODE = ["Fattura unica", "Fatture multiple"];
export interface InvoiceFilters {
  org?: Org;
  from?: Date;
  to?: Date;
  identifier?: number;
  canceled?: boolean;
  downloaded?: boolean;
  download_date_from?: Date;
  download_date_to?: Date;
}

export interface InvoiceDTO extends BaseDTO {
  client_id: number;
  client: OrgDTO | EmployeeDTO;
  client_type: "App\\Org" | "App\\Employee";
  billing_lines: BillingLineDTO[];
  date: string;
  payment_method: PaymentMethod;
  payment_time: PaymentTime;
  payed: boolean;
  identifier: number;
  code: number;
  canceled_date?: string;
  download_date?: string;
}

export class Invoice extends Base {
  client: Org | Employee;
  clientType: "App\\Org" | "App\\Employee";
  billingLines: BillingLine[];
  date: Date;
  paymentMethod: PaymentMethod;
  paymentTime: PaymentTime;
  payed: boolean;
  identifier: number;
  code: number;
  canceledDate: Date;
  downloadDate: Date;

  constructor(dto: InvoiceDTO, loadedRelations?: string[]) {
    super(dto, loadedRelations);
    if (dto) {
      if (dto.client) {
        this.client =
          dto.client_type == "App\\Org"
            ? new Org(<OrgDTO>dto.client)
            : new Employee(<EmployeeDTO>dto.client);
        this.addLoadedRelation("client");
      }
      if (dto.billing_lines) {
        this.billingLines = dto.billing_lines.map(dto => new BillingLine(dto));
        this.addLoadedRelation("billing_lines");
      }
      this.date = new Date(dto.date);
      this.clientType = dto.client_type;
      this.payed = dto.payed;
      this.paymentMethod = dto.payment_method;
      this.paymentTime = dto.payment_time;
      this.identifier = dto.identifier;
      this.code = dto.code;
      if (dto.canceled_date) {
        this.canceledDate = new Date(dto.canceled_date);
      }
      if (dto.download_date) {
        this.downloadDate = new Date(dto.download_date);
      }
    }
  }

  public toDTO(): InvoiceDTO {
    const result: InvoiceDTO = <InvoiceDTO>super.toDTO()
    if (this.client) {
      result.client = this.client.toDTO();
      result.client_id = this.client.objectId;
      if (this.client instanceof Org) {
        result.client_type = "App\\Org";
      }
      if (this.client instanceof Employee) {
        result.client_type = "App\\Employee";
      }
    }
    result.client_type = this.clientType;
    result.date = formatDateForBackend(this.date, true);
    result.payed = this.payed;
    result.payment_method = this.paymentMethod;
    result.payment_time = this.paymentTime;
    result.billing_lines = this.billingLines
      ? this.billingLines.map((billingLine) => billingLine.toDTO())
      : null;
    result.identifier = this.identifier;
    result.code = this.code;
    result.canceled_date = formatDateForBackend(this.canceledDate);
    result.download_date = formatDateForBackend(this.downloadDate);
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Invoice, client?: Org | Employee): Invoice {
    const formModel = formGroup.getRawValue();

    let invoice: Invoice = new Invoice(null);
    invoice.date = formModel.date;
    invoice.billingLines = formModel.billingLines;
    invoice.payed = formModel.payed;
    invoice.paymentMethod = formModel.paymentMethod;
    invoice.paymentTime = formModel.paymentTime;
    invoice.identifier = formModel.identifier;
    invoice.code = formModel.code;
    invoice.canceledDate = formModel.canceledDate;
    invoice.client = client;
    invoice.clientType = formModel.clientType;

    if (original) {
      invoice.objectId = original.objectId;
    }
    return invoice;
  }

  get invoiceCode(): string {
    return `${this.identifier}/${this.code}`
  }

  get clientName(): string {
    return this.clientType == 'App\\Employee' ? (this.client as Employee).fullName : (this.client as Org).name;
  }

  get amount(): number {
    let amount: number = 0
    this.billingLines.forEach((billingLine: BillingLine) => amount += billingLine.amount);
    return amount;
  }

}
